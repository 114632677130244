import React from 'react';

import { Layout, SEO } from 'components';

import { useMatch } from '@reach/router';
import Article from '../../containers/article/Article';
import getArticleData from '../../queries/article.module';

const ArticlePage = () => {
  const match = useMatch('/article/:id');
  const { id } = match;
  const { allContentfulArticle } = getArticleData();
  const article = allContentfulArticle.nodes.find((a) => a.articleLink === id);

  return (
    <Layout>
      <SEO title={article?.articleTitle} />
      <Article
        articleText={article?.articleText}
        articleTitle={article?.articleTitle}
      />
    </Layout>
  );
};

export default ArticlePage;
