import React from 'react';
import { Container, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ContentfulContent from '../../components/contentfulContent/ContentfulContent';

import styles from './style';

const useStyles = makeStyles(styles);

const Article = ({ articleText, articleTitle }) => {
  const classes = useStyles();
  return (
    <Container maxWidth='md'>
      <Fade in timeout={1500}>
        <h1 className={classes.Title}>{articleTitle}</h1>
      </Fade>
      <Fade in timeout={1500}>
        <div className={classes.Line} />
      </Fade>
      <Fade in timeout={1500}>
        <div className={classes.Text}>
          <ContentfulContent content={articleText?.json} />
        </div>
      </Fade>
    </Container>
  );
};

export default Article;
