const styles = () => ({
  Title: {
    textAlign: 'center',
  },
  Line: {
    height: '1px',
    backgroundColor: 'lightgrey',
    width: '100%',
    marginBottom: '2rem',
  },
  Text: {
    lineHeight: '1.4',
    fontSize: '1.4rem',
  },
});

export default styles;
