import { useStaticQuery, graphql } from 'gatsby';

const getArticlesData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulArticle {
          nodes {
            articleTitle
            articleText {
              json
            }
            id
            mainPicture {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            articleLink
          }
        }
      }
    `,
  );
  return data;
};

export default getArticlesData;
